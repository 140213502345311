import { ChangeDetectionStrategy, Component, HostListener, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

/**
 * Handles toggling and indicating favorite nodes in the hierarchy.
 */
@Component({
  selector: 'lib-hierarchy-favorite-icon',
  imports: [CommonModule, SvgIconComponent],
  template: `
    <div
      class="hover:bg-surface-300 dark:hover:bg-surface-600 flex size-8 items-center justify-center rounded-lg"
      (click)="toggleFavorite.emit(); $event.stopPropagation()">
      @if (isFavorite()) {
        <svg-icon class="text-yellow-500 dark:text-yellow-500" key="star-filled"></svg-icon>
      } @else {
        <svg-icon class="text-black dark:text-white" key="star"></svg-icon>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HierarchyFavoriteIconComponent {
  isFavorite = input<boolean>(true);
  toggleFavorite = output<void>();

  hover = signal<boolean>(false);

  @HostListener('mouseenter')
  onMouseEnter() {
    this.hover.set(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hover.set(false);
  }
}
