<div class="node-name">
  <!-- Entry icon -->
  @if (hierarchyNode().type !== 'root') {
    <div class="icon" (click)="expand($event)">
      @if (hasChild()) {
        <div class="group-expand">
          <svg-icon key="chevron-down"></svg-icon>
        </div>
      }
      <lib-hierarchy-node-icon [hierarchyNode]="hierarchyNode()"> </lib-hierarchy-node-icon>
    </div>
  }

  <!-- Entry name -->

  <a [routerLink]="[]" [queryParams]="queryParams()">
    @if (hierarchyNode().isUngroupedAssetsCollection) {
      {{ 'Not grouped' | translate }}
    } @else {
      {{ hierarchyNode().name }}
    }
  </a>

  <!-- Energy blocks -->
  <div class="energy-blocks">
    @for (energyBlock of energyBlocksForNode(); track energyBlock.id) {
      <div [pTooltip]="energyBlock.name | translate" tooltipPosition="bottom">
        <span [style.background]="energyBlock.color"></span>
      </div>
    }
  </div>
</div>

<!-- Tool buttons -->
@if (hierarchyNode().type !== 'root') {
  @if (showConfigMenu() && visibleMenuItems().length) {
    <div class="add" (click)="menu.toggle($event); $event.stopPropagation()">
      <svg-icon key="plus"></svg-icon>
    </div>
  }

  <lib-hierarchy-favorite-icon
    class="favorite"
    [ngClass]="{ selected: isFavorite() }"
    [isFavorite]="isFavorite()"
    (toggleFavorite)="toggleFavorite.emit(hierarchyNode())">
  </lib-hierarchy-favorite-icon>
}

<p-menu #menu [model]="visibleMenuItems()" [popup]="true" appendTo="body">
  <ng-template pTemplate="item" let-item>
    <a class="p-menu-item-link flex items-center" [routerLink]="[]" [queryParams]="item.queryParams">
      <svg-icon [key]="item.icon"></svg-icon>
      <span class="ml-2">{{ item.label | translate }}</span>
    </a>
  </ng-template>
</p-menu>
