import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getEnv } from '@logic-suite/shared/utils';
import { AllCommunityModule } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry, ValidationModule } from 'ag-grid-enterprise';

if (environment.production) {
  enableProdMode();
}

async function bootstrap() {
  // Set license key for AG-Grid.
  LicenseManager.setLicenseKey(getEnv('agGridLicenseKey'));

  // Register AG-Grid modules.
  ModuleRegistry.registerModules([
    // TODO(bjhandeland): Remove this one in dev.
    ValidationModule,
    // TODO(bjhandeland): Replace this with only the modules we use.
    // Community modules.
    AllCommunityModule,
    // TODO(bjhandeland): Replace this with only the modules we use.
    // Enterprise modules.
    AllEnterpriseModule,
  ]);

  // Load version from version.txt.
  const version = await fetch(`assets/version.txt?d=${new Date().getTime()}`)
    .then((response) => response.text())
    .catch(() => '');

  const isSemver = /^\d+\.\d+\.\d+/.test(version || '');

  if (isSemver) {
    Sentry.init({
      dsn: getEnv('sentryDSN'),
      release: version,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', /noova.no\/api/],
      environment: getEnv('env'),
    });
  }

  try {
    await platformBrowserDynamic().bootstrapModule(AppModule);
  } catch (err) {
    console.error(err);
  }
}

bootstrap();
