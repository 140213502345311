@for (entry of getFavoriteNodes() | keyvalue: compareNodeTypes; track entry.key) {
  <div class="mb-4">
    <div class="text-surface-400 mb-1 truncate px-2 pt-2 text-xs font-bold">
      {{ entry.key | uppercase | translate }}
    </div>

    <cdk-tree #favoriteTree [dataSource]="entry.value" [childrenAccessor]="childrenAccessor">
      <!-- Leaf nodes -->
      <cdk-nested-tree-node *cdkTreeNodeDef="let node" [isExpandable]="false">
        <lib-hierarchy-node
          class="mb-1 flex flex-col"
          [hierarchyNode]="node"
          [selectedNode]="selectedNode()"
          [favorites]="favorites()"
          [energyBlocksForNode]="energyBlocksForNode()(node)"
          [showConfigMenu]="showConfigMenu()"
          (selectNode)="selectNode.emit(node)"
          (toggleFavorite)="toggleFavorite.emit(node)">
        </lib-hierarchy-node>
      </cdk-nested-tree-node>

      <!-- Expandable nodes -->
      <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" [isExpandable]="true">
        <lib-hierarchy-node
          class="mb-1 flex flex-col"
          [hierarchyNode]="node"
          [selectedNode]="selectedNode()"
          [favorites]="favorites()"
          [energyBlocksForNode]="energyBlocksForNode()(node)"
          [showConfigMenu]="showConfigMenu()"
          (selectNode)="selectNode.emit(node)"
          (toggleNode)="favoriteTree.toggle(node)"
          (toggleFavorite)="toggleFavorite.emit(node)">
        </lib-hierarchy-node>

        @if (favoriteTree.isExpanded(node)) {
          <div class="pl-4">
            <ng-container cdkTreeNodeOutlet></ng-container>
          </div>
        }
      </cdk-nested-tree-node>
    </cdk-tree>
  </div>
}
