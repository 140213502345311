import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Favorite } from '@suite/favorite';
import { HierarchyNode } from '@suite/hierarchy-data-access';
import { HierarchyFavoriteIconComponent } from '@suite/hierarchy-favorite-icon';
import { HierarchyNodeIconComponent } from '@suite/hierarchy-node-icon';
import { MenuItem, SharedModule } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { TranslateModule } from '@ngx-translate/core';
import { EnergyBlock } from '@suite/energy-blocks-data-access';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'lib-hierarchy-node',
  imports: [
    CommonModule,
    RouterModule,
    HierarchyNodeIconComponent,
    HierarchyFavoriteIconComponent,
    SvgIconComponent,
    MenuModule,
    SharedModule,
    TranslateModule,
    Tooltip,
  ],
  templateUrl: './hierarchy-node.component.html',
  styleUrl: './hierarchy-node.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HierarchyNodeComponent implements OnInit {
  hierarchyNode = input.required<HierarchyNode>();
  favorites = input<Map<string, Favorite>>(new Map<string, Favorite>());
  energyBlocksForNode = input<EnergyBlock[]>([]);
  selectedNode = input<HierarchyNode | null>(null);
  showConfigMenu = input<boolean>();
  selectNode = output<void>();
  toggleNode = output<void>();
  toggleFavorite = output<HierarchyNode>();

  addMenuItems = signal<MenuItem[]>([]);
  visibleMenuItems = computed(() => this.addMenuItems().filter((menuItem) => menuItem.visible));
  queryParams = computed(() => {
    const { customer, ...params } = this.hierarchyNode().getQueryParams();
    return params;
  });

  @HostBinding('class.inactive')
  get isInactive() {
    return !this.hierarchyNode().active;
  }

  @HostBinding('class.selected')
  get __isSelected() {
    return this.isSelected();
  }

  hasChild() {
    return this.hierarchyNode().children.length !== 0;
  }

  isSelected(): boolean {
    if (this.selectedNode() === null && this.hierarchyNode().type === 'root') {
      return true;
    }

    return this.selectedNode()?.getUniqueId() === this.hierarchyNode().getUniqueId();
  }

  isFavorite() {
    const id = this.hierarchyNode().getUniqueId();
    const type = this.hierarchyNode().type;
    return this.favorites().has(`${type}:${id}`);
  }

  expand($event: MouseEvent) {
    if (!this.hasChild()) return;
    $event.stopPropagation();
    this.toggleNode.emit();
  }

  ngOnInit() {
    // TODO(bjhandeland): Figure out whether this should be here or not.
    // It feels like it should be somewhere else...
    this.addMenuItems.set([
      {
        label: 'Asset group',
        icon: 'building-02',
        queryParams: { ...this.hierarchyNode().getQueryParams(), assetGroup: 'new' },
        visible: this.hierarchyNode().type === 'root',
      },
      {
        label: 'Asset',
        icon: 'building-01',
        queryParams: { ...this.hierarchyNode().getQueryParams(), asset: 'new' },
        visible: ['root', 'assetGroup'].includes(this.hierarchyNode().type),
      },
      {
        label: 'Section',
        icon: 'section',
        queryParams: { ...this.hierarchyNode().getQueryParams(), section: 'new' },
        visible: this.hierarchyNode().type === 'asset',
      },
      {
        label: 'Level',
        icon: 'floor',
        queryParams: { ...this.hierarchyNode().getQueryParams(), level: 'new' },
        visible: this.hierarchyNode().type === 'section',
      },
      {
        label: 'Zone',
        icon: 'zone',
        queryParams: { ...this.hierarchyNode().getQueryParams(), zone: 'new' },
        visible: this.hierarchyNode().type === 'level',
      },
      {
        label: 'Room',
        icon: 'room',
        queryParams: { ...this.hierarchyNode().getQueryParams(), room: 'new' },
        visible: this.hierarchyNode().type === 'zone',
      },
      {
        label: 'Resource',
        icon: 'resource',
        queryParams: { ...this.hierarchyNode().getQueryParams(), resource: 'new' },
        visible: this.hierarchyNode().type === 'room',
      },
      {
        label: 'Metering point group',
        icon: 'meter-group',
        queryParams: { ...this.hierarchyNode().getQueryParams(), assetMeteringPointGroup: 'new' },
        visible: ['asset', 'assetGroup'].includes(this.hierarchyNode().type),
      },
      {
        label: 'Virtual meter',
        icon: 'virtual-meter',
        queryParams: { ...this.hierarchyNode().getQueryParams(), virtualMeteringPoint: 'new' },
        visible: ['asset', 'meteringPoint', 'assetMeteringPointGroup'].includes(this.hierarchyNode().type),
      },
    ]);
  }
}
