import { CdkNestedTreeNode, CdkTree, CdkTreeNodeDef, CdkTreeNodeOutlet } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Favorite } from '@suite/favorite';
import { HierarchyNode } from '@suite/hierarchy-data-access';
import { HierarchyNodeComponent } from '@suite/hierarchy-node';
import { EnergyBlock } from '@suite/energy-blocks-data-access';

@Component({
  selector: 'lib-full-hierarchy',
  imports: [
    CommonModule,
    CdkNestedTreeNode,
    CdkTree,
    CdkTreeNodeOutlet,
    HierarchyNodeComponent,
    TranslateModule,
    CdkTreeNodeDef,
  ],
  templateUrl: './full-hierarchy.component.html',
  styleUrl: './full-hierarchy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullHierarchyComponent {
  hierarchy = input.required<HierarchyNode[]>();
  favorites = input<Map<string, Favorite>>(new Map<string, Favorite>());
  selectedNode = input<HierarchyNode | null>(null);
  energyBlocks = input<EnergyBlock[]>([]);
  showConfigMenu = input<boolean>();
  selectNode = output<HierarchyNode>();
  toggleFavorite = output<HierarchyNode>();

  childrenAccessor = (hierarchyNode: HierarchyNode) => hierarchyNode.children ?? [];

  hasChild = (_: number, hierarchyNode: HierarchyNode) => hierarchyNode.hasChildren();

  @ViewChild('fullTree') fullTree!: CdkTree<HierarchyNode>;

  constructor() {
    effect(() => {
      let node = this.selectedNode();
      if (!node) {
        // We are on root. Expand
        const root = this.hierarchy();
        if (root?.length && root[0].children.length === 1) {
          // Root only has one child. Expand it
          node = root[0].children[0].children[0];
        }
      }

      // Without setTimeout, the tree will not expand the root nodes only assetGroup
      setTimeout(() => this.expand(node));
    });
  }

  energyBlocksForNode = computed(() => (node: HierarchyNode) => {
    return this.energyBlocks().filter((energyBlock) => node.energyBlocks?.includes(energyBlock.id));
  });

  expand = (hierarchyNode: HierarchyNode | null) => {
    if (!hierarchyNode) {
      return;
    }

    if (hierarchyNode.parent) {
      this.expand(hierarchyNode.parent);
      this.fullTree.expand(hierarchyNode.parent);
    }
  };
  protected readonly HierarchyNode = HierarchyNode;
}
