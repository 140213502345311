import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { combineLatest, filter, map, Subscription, timer } from 'rxjs';

import { dropdown } from '@logic-suite/shared/animations';
import { AppService } from '@logic-suite/shared/app.service';
import { AuthService } from '@logic-suite/shared/auth';
import { TelemetryService } from '@logic-suite/shared/telemetry';
import { Theme, ThemeService } from '@logic-suite/shared/theme';
import { Browser, getEnv } from '@logic-suite/shared/utils';
import { AccessService, Application, CustomerService, ICustomer } from './shared/access';
import { toSignal } from '@angular/core/rxjs-interop';
import { FeatureFlagService } from '@logic-suite/shared/feature-flag/feature-flag.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [dropdown],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  private auth = inject(AuthService);
  private customerService = inject(CustomerService);
  private telemetry = inject(TelemetryService);
  private dialog = inject(MatDialog);
  private app = inject(AppService);
  private access = inject(AccessService);
  private theme = inject(ThemeService);
  private router = inject(Router);
  private updates = inject(SwUpdate);
  private featureFlag = inject(FeatureFlagService);
  subscriptions: Subscription[] = [];

  private routeStart = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map((e) => e as NavigationStart),
    ),
  );
  private routeEnd = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e) => e as NavigationEnd),
    ),
  );
  returnState = computed(() => {
    const route = this.routeStart(); // Trigger on route start
    if (route) {
      const query = this.access.packQuery(this.access.getQueryParams());
      return this.access.generateState({
        returnRoute: `${location.pathname}${query.length ? '?' + query : ''}`,
      });
    }
    return '';
  });

  @HostBinding('class.with-rail')
  get withRails() {
    return this.featureFlag.enabledFlagIds().includes('chameleon/rail');
  }

  @HostBinding('class.framed')
  get inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  @HostBinding('class')
  get browserClass() {
    const b = Browser.getBrowser();
    // Add browser info
    const classList = [`UA_${b.browser}`, `UA_${b.version}`];
    // Add environment info
    if (location.hostname.indexOf('dev') > -1 || this.env === 'dev') classList.push('dev');
    else if (location.hostname.indexOf('test') > -1 || this.env === 'test') classList.push('test');
    return classList.join(' ');
  }

  isRoot = computed(() => {
    const route = this.routeEnd() as NavigationEnd;
    return route?.urlAfterRedirects.split('?')[0] === '/';
  });
  currentCustomer = signal({ name: '', customerID: -1 } as ICustomer);
  companyName = computed(() => this.currentCustomer().name);

  @ViewChild('menuTrigger', { read: ElementRef }) menuTrigger!: ElementRef<HTMLElement>;

  @HostBinding('class.with-toolbar')
  isAuthenticated = false;

  menuVisible = signal(false);
  env = getEnv('env');
  appID = 0;
  logo!: string;
  canToggleTheme = environment.allowTheming;

  constructor() {
    // Make sure we run on an updated version
    if (this.updates.isEnabled) {
      // Ask user for permission to update once a new update is registered
      this.subscriptions.push(
        this.updates.versionUpdates
          .pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map((evt) => ({
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion,
            })),
          )
          .subscribe(() => {
            if (confirm('A newer version of the application is available. Load the new version?')) {
              this.updates.activateUpdate().then(() => document.location.reload());
            }
          }),
      );
      // Check every 5 minutes for updates
      this.subscriptions.push(timer(0, 5 * 60 * 1000).subscribe(() => this.updates.checkForUpdate()));
    } else if (isPlatformBrowser(PLATFORM_ID)) {
      console.log('SW not enabled!');
    }
  }

  ngOnInit() {
    combineLatest([
      this.access.getApplicationList(),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart),
        map((e) => e as NavigationStart),
      ),
    ]).subscribe({
      next: ([apps, event]: [Application[], NavigationStart]) => {
        try {
          const url = event.url.substring(1).split('/')[0].split('?')[0];
          if (!['help', 'settings', 'select-customer', 'login', 'callback', 'palette'].includes(url)) {
            const currentApp = apps?.find((a) => a.applicationUrl.split('/').join('') === url);
            const appID = currentApp?.applicationID ?? 1;
            this.app.setApplicationID(appID);
          }
        } catch (ex) {
          //
        }
      },
      error: (err) => console.error(err),
    });

    // Start telemetry
    this.telemetry.init();

    // Enable/Disable main menu based on authentication and affiliate selection.
    this.subscriptions.push(
      combineLatest([this.auth.isLoggedIn$, this.customerService.selectedCustomer$]).subscribe(
        ([isLoggedIn, customer]) => {
          this.currentCustomer.set(customer);
          this.isAuthenticated = isLoggedIn && !!customer;
        },
      ),
    );

    // Listen for theme changes
    if (environment.allowTheming) {
      this.theme.listen();
    } else {
      // Default is light theme
      this.theme.setTheme(Theme.LIGHT);
    }

    // Listen for app changes
    this.subscriptions.push(
      this.app.application$.subscribe((appID) => {
        this.appID = appID;
        let url = this.access.getApplication(this.appID)?.logoUrl;
        url = url ? url.split('/').join('') : 'suite';
        setTimeout(() => {
          this.logo = `assets/${url}_logo`;
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleMenu() {
    this.dialog.closeAll();
    this.menuVisible.set(!this.menuVisible());
  }

  @HostListener('window:click', ['$event'])
  onClick(evt: MouseEvent) {
    const clickOnTrigger =
      this.menuTrigger && (evt.target as HTMLElement).closest('a') === this.menuTrigger.nativeElement;
    // const clickOnMenu =  (evt.target as HTMLElement).closest('app-menu') !== null;
    if (this.menuVisible() && !clickOnTrigger) {
      this.menuVisible.set(false);
    }
  }
}
